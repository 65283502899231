import React from 'react';
import Article, { SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';
import { DevelopersLink } from '../../components/Link';
import { Information } from '../../components/information';

const Content = () => {
  return (
    <div>
      <SubTitle>How does it work?</SubTitle>
      <p>
        If you have already set up custom events in your Segment account, you can re-use them with
        LiveSession. You can configure LiveSession callback to collect data every time the Segment’s
        tracking code sends an event.
      </p>
      <p>Edit the code below according to your needs to start tracking Segment events.</p>
      <Information color="warning">
        Custom Events are available via JS API. Learn more about limitations and best practices{' '}
        <DevelopersLink blank href="/javascript-api/methods/#track">
          here
        </DevelopersLink>
        .{' '}
      </Information>
      <SubTitle className="mt-5">How can I route events from Segment to LiveSession?</SubTitle>
      <span>
        If you have already set up Segment and LiveSession tracking codes, adding the code below
        will enable you to send events from Segment to LiveSession:
      </span>
      <Code>
        {`<script>
    if (analytics && __ls) {
        analytics.on("track", function(event, properties) {
          __ls("track", event, properties)
        });
    }
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works, you need to have Segment and LiveSession installed on your
        website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [
  {
    link: '/help/segment-integration/',
    title: 'LiveSession and Segment integration',
    description: 'Send LiveSession recordings as events to Segment',
  },
  {
    link: '/help/how-do-the-custom-events-work/',
    title: 'How does the Custom Events work?',
    description: 'Wondering about the usability of our Custom Events? Read here.',
  },
];

export const frontmatter = {
  metaTitle: 'How to connect events from Segment?',
  metaDescription: 'Take a look how to pass Segment events to LiveSession',
  canonical: '/help/how-to-connect-events-from-segment',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Developer" title={frontmatter.metaTitle} {...frontmatter} />
);
